<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-plain">
          <tabs centered square>
            <tab-pane>
              <template slot="label">
                <div class="d-flex align-items-center">
                  <div class="text-uppercase m-3">{{ $t('fields.operator') }}</div>
                </div>
              </template>
              <octo-header-button @onSave="saveSettings" :buttons="headerButtons"/>
              <call-center-setting-calendar ref="callCenterSettingCalendar"/>
              <call-center-setting-deadlines ref="callCenterSettingDeadlines"/>
            </tab-pane>
            <tab-pane>
              <template slot="label">
                <div class="d-flex align-items-center">
                  <div class="text-uppercase m-3">{{ $t('callcenter.production_awards') }}</div>
                </div>
              </template>
              <octo-header-button @onSave="saveSettings" :buttons="headerButtons"/>
              <call-center-setting-awards ref="callCenterSettingAwards"/>
              <call-center-setting-award-deal-thresholds ref="callCenterSettingAwardDealThresholds"/>
            </tab-pane>
            <tab-pane>
              <template slot="label">
                <div class="d-flex align-items-center">
                  <div class="text-uppercase m-3">{{ $t('sidebar.salaries') }}</div>
                </div>
              </template>
              <octo-header-button @onSave="saveSalaries" :buttons="headerButtons"/>
              <users-salaries
                ref="operatorsSalaries"
                :users-list="operators"
                :title="$t('fields.call_center_operators')"
              />
            </tab-pane>
            <tab-pane>
              <template slot="label">
                <div class="d-flex align-items-center">
                  <div class="text-uppercase m-3">{{ $t('callcenter.profile') }}</div>
                </div>
              </template>
              <octo-header-button @onSave="saveProfiles" :buttons="headerButtons"/>
              <users-profiles
                ref="operatorsProfiles"
                :users-list="operators"
                :title="$t('fields.call_center_operators')"
              />
            </tab-pane>
          </tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import CallCenterSettingCalendar from "@/pages/Settings/components/CallCenterSettingCalendar";
import CallCenterSettingDeadlines from "@/pages/Settings/components/CallCenterSettingDeadlines";
import CallCenterSettingAwards from "@/pages/Settings/components/CallCenterSettingAwards";
import CallCenterSettingAwardDealThresholds from "@/pages/Settings/components/CallCenterSettingAwardDealThresholds";
import CallCenterPageHelper from "@/components/Helpers/CallCenterPageHelper";
import {TabPane, Tabs} from "@/components";
import UsersSalaries from "./components/UsersSalaries";
import {endpoints} from "@/routes/endpoints";
import UsersProfiles from "./components/UsersProfiles";

export default {
  name: "CallCenterPage",
  components: {
    UsersProfiles,
    UsersSalaries,
    CallCenterSettingAwardDealThresholds,
    CallCenterSettingAwards,
    CallCenterSettingDeadlines,
    CallCenterSettingCalendar,
    OctoHeaderButton,
    Tabs,
    TabPane,
  },
  data() {
    return {
      headerButtons: [
        {
          label: 'save',
          onClick: 'onSave',
        },
      ],
      helperComponent: CallCenterPageHelper
    }
  },
  computed: {
    ...mapGetters({
      operators: 'common/operators'
    })
  },

  mounted() {
    this.setHelper(this.helperComponent);
  },

  methods: {
    ...mapActions({
      updateSettings: 'common/updateSettings',
      getUsers: 'common/getUsers',
    }),

    ...mapMutations({
      setHelper: 'common/setHelper',
    }),

    saveSettings: async function () {
      this.$fullLoading.show();

      try {
        const data = this.$_.union(
          this.$refs.callCenterSettingCalendar.getSettings(),
          await this.$refs.callCenterSettingDeadlines.getSettings(),
          this.$refs.callCenterSettingAwards.getSettings(),
          this.$refs.callCenterSettingAwardDealThresholds.getSettings(),
        );

        await this.updateSettings(data);

        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },

    saveSalaries: async function () {
      try {
        let operatorsData = await this.$refs.operatorsSalaries.getUsersData();
        this.$fullLoading.show();
        await this.$api.put(endpoints.USER_UPDATE_SALARIES, {salaries: operatorsData})
        await this.getUsers();

        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (ex) {
        this.$notify({type: 'danger', message: this.$t('common.wrong_field')});
      } finally {
        this.$fullLoading.hide();
      }
    },

    saveProfiles: async function () {
      try {
        let operatorsData = this.$refs.operatorsProfiles.getUsersData();

        this.$fullLoading.show();
        await this.$api.put(endpoints.USER_UPDATE_PROFILES, {profiles: operatorsData})
        await this.getUsers();

        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (ex) {
        this.$notify({type: 'danger', message: this.$t('common.wrong_field')});
      } finally {
        this.$fullLoading.hide();
      }
    },
  },

  beforeDestroy() {
    this.setHelper()
  }
}
</script>

<style scoped lang="scss">

</style>
