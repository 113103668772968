<template>
  <div>
    <p class="text-uppercase">{{ $t('callcenter.callcenter_agenda') }}</p>
    <collapse :multiple-active="false">
      <collapse-item :title="$t('callcenter.calendar_day')">
        <div class="small mb-2">
          <label-theme-component>Setting:</label-theme-component>
          {{ settingConst.CALLCENTER_CALENDAR_DAY }}
        </div>
        <div>Numero di giorni in cui l'operatore call center è abilitato a fissare un appuntamento</div>
      </collapse-item>
      <collapse-item :title="$t('callcenter.calendar_schedule')">
        <div class="small mb-2">
          <label-theme-component>Setting:</label-theme-component>
          {{ settingConst.CALLCENTER_CALENDAR_START }}, {{ settingConst.CALLCENTER_CALENDAR_END }}
        </div>
        <div>Definisce l'ora di inizio e fine che sono usati negli scheduler</div>
      </collapse-item>
      <collapse-item :title="$t('callcenter.appointment_duration')">
        <label-theme-component>Setting:</label-theme-component>
        {{ settingConst.CALLCENTER_APPOINTMENT_DURATION }}
        <div>Definisce la durata in minuti dell'appuntamento fissato dall'operatore call center</div>
      </collapse-item>
    </collapse>
    <p class="text-uppercase mt-3">{{ $t('callcenter.deadline_settings') }}</p>
    <collapse :multiple-active="false">
      <collapse-item :title="$t('callcenter.deadline_if_busy')">
        <div class="small mb-2">
          <label-theme-component>Setting:</label-theme-component>
          {{ settingConst.CALLCENTER_DEADLINES }}.busy
        </div>
        <div>Minuti della deadline impostata se l'operatore indica la lead come occupata</div>
      </collapse-item>
      <collapse-item :title="$t('callcenter.deadline_if_not_answer')">
        <div class="small mb-2">
          <label-theme-component>Setting:</label-theme-component>
          {{ settingConst.CALLCENTER_DEADLINES }}.not_answer
        </div>
        <div>Minuti della deadline impostata se l'operatore indica che la lead non ha risposto</div>
      </collapse-item>
      <collapse-item :title="$t('callcenter.deadline_if_unattainable')">
        <div class="small mb-2">
          <label-theme-component>Setting:</label-theme-component>
          {{ settingConst.CALLCENTER_DEADLINES }}.unattainable
        </div>
        <div>Minuti della deadline impostata se l'operatore indica che la lead non è rintracciabile</div>
      </collapse-item>
    </collapse>
    <p class="text-uppercase mt-3">{{ $t('callcenter.production_awards') }}</p>
    <collapse :multiple-active="false">
      <collapse-item :title="$t('callcenter.production_awards_appointment')">
        <div class="small mb-2">
          <label-theme-component>Setting:</label-theme-component>
          {{ settingConst.CALLCENTER_AWARD_APPOINTMENT }}
        </div>
        <div>
          Premio dato all'operatore in base al suo profilo e al suo tipo ( Full Time / Part Time )
          per ogni lead che ha una opportunità con uscita nel mese di cui si calcola il bilancio
          e che si trova in stato: <br>
          PROMOSSO<br>
          CHIUSO<br>
          SOSPESO<br>
          NON INTERESSATO<br>
          RESPINTO DA CONTABILITA'
        </div>
      </collapse-item>
      <collapse-item :title="$t('callcenter.awards_deal_operator')">
        <div class="small mb-2">
          <label-theme-component>Setting:</label-theme-component>
          {{ settingConst.CALLCENTER_AWARD_DEAL_OPERATOR }}
        </div>
        <div>
          Premio dato all'operatore per ogni opportunità che ha una data di uscita nel mese in cui si calcola il
          bilancio e che si trova nello stato:<br>
          CHIUSO<br>
          PROMOSSO<br>
          SOSPESO<br>
          RESPINTO DA CONTABILITA'
        </div>
      </collapse-item>
      <collapse-item :title="$t('callcenter.awards_deal_manager')">
        <div class="small mb-2">
          <label-theme-component>Setting:</label-theme-component>
          {{ settingConst.CALLCENTER_AWARD_DEAL_MANAGER }}
        </div>
        <div>
          Premio dato ad ogni manager per ogni opportunità che ha una data di uscita nel mese in cui si calcola il
          bilancio e che si trova nello stato:<br>
          CHIUSO<br>
          PROMOSSO<br>
          SOSPESO<br>
          RESPINTO DA CONTABILITA'
        </div>
      </collapse-item>
    </collapse>
    <p class="text-uppercase mt-3">{{ $t('callcenter.cumulative_awards') }}</p>
    <collapse :multiple-active="false">
      <collapse-item :title="`${$t('callcenter.thresholds')} [${$t('callcenter.personal')}]`">
        <div class="small mb-2">
          <label-theme-component>Setting:</label-theme-component>
          {{ settingConst.CALLCENTER_AWARD_DEAL_THRESHOLDS_PERSONAL }}
        </div>
        <div>
          Premio cumulativo dato all'operatore ed al menager per il superamento delle soglie dei proprie opportunità
          che hanno una data di uscita nel mese in cui si calcola il
          bilancio e che si trovano nello stato:<br>
          CHIUSO<br>
          PROMOSSO<br>
          SOSPESO<br>
          RESPINTO DA CONTABILITA'
        </div>
      </collapse-item>
      <collapse-item :title="`${$t('callcenter.thresholds')} [${$t('callcenter.group')}]`">
        <div class="small mb-2">
          <label-theme-component>Setting:</label-theme-component>
          {{ settingConst.CALLCENTER_AWARD_DEAL_THRESHOLDS_GROUP }}
        </div>
        <div>
          Premio cumulativo dato all'operatore ed al manager per il superamento delle soglie delle opportunità
          di tutti gli operatori che hanno una data di uscita nel mese in cui si calcola il
          bilancio e che si trovano nello stato:<br>
          CHIUSO<br>
          PROMOSSO<br>
          SOSPESO<br>
          RESPINTO DA CONTABILITA'
        </div>
      </collapse-item>
    </collapse>
  </div>
</template>

<script>
import CollapseItem from "@/components/Collapse/CollapseItem";
import Collapse from "@/components/Collapse/Collapse";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import settingConst from "@/pages/Settings/resources/settingConst";

export default {
  name: "CallCenterPageHelper",
  components: {
    LabelThemeComponent,
    CollapseItem,
    Collapse,
  },
  data() {
    return {
      settingConst: settingConst
    }
  }
}
</script>

<style scoped>

</style>
